.faq-component{
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.faq-component-heading{
    color: var(--primary-color);

}


.faq-component-heading-text{
    font-family: 'Effra', sans-serif;
    font-size: 18px;
}



.faq-component-description{
    font-family: 'Effra', sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: var(--primary-color);
}