.navigation{
    min-height: 135px;
}


/* Css of Navigation starts here */

.top-discount-banner{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Effra',  sans-serif;
    font-size: 18px;
    font-weight: 300;
    color: white;
    padding: 6px 0px;
    background-color: var(--primary-color);
}

.discount-bold{
    font-family: 'Effra',  sans-serif;
    font-size: 18px;
    font-weight: 600;
   margin: 0px 5px;
   
}


/* here starts the actual menu  */

.header-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
padding: 20px 0px ;

}


.nav-menu{
    gap: 50px;
}


.nav-menu img{
    padding-left: 20px;
    width: 180px;
}

.nav-links-centered{
    display: flex;
    align-items: center;
}

.nav-links{
    font-family: 'Effra',  sans-serif;
    gap: 40px;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
}


.other-menu{
    gap: 30px;
}

.other-menu button:nth-child(1){
    width: 100px;
}

.other-menu button:nth-child(2){
    width: 175px;
}

.nav-icon{
    color: #939fa4;
    cursor: pointer;
}

.cart-value{
    font-family: 'Effra',  sans-serif;
    color: #939fa4;
    cursor: pointer;
    padding-right: 20px;
}

.nav-mobile{
    display: none;
}

@media screen and (max-width: 1150px) {
    .top-discount-banner{
        font-size: 15px;
    }
    .discount-bold{
        font-size: 15px; 
    }
    .other-menu button{
        display: none;
    }
    .nav-menu img{
        width: 150px;
    }
    
}

@media screen and (max-width: 768px) {
    .nav-menu nav{
        display: none;
    }
}


@media screen and (max-width: 425px) {
    .top-discount-banner{
        font-size: 12px;
    }
    .discount-bold{
        font-size: 12px; 
    }
    .nav-menu img{
        width: 100px;
    }
    .cart-value{
        padding-right: 0px;
    }
    .nav-mobile{
        display: block;
        padding-right: 20px;
    }
}

