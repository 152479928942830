body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0e5df;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Tailwind css  */

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Edensor";
  src: url("/public/fonts/Edensor.woff2") format("woff2");
}

@font-face {
  font-family: "Effra";
  src: url("/public/fonts/Effra.woff2") format("woff2");
}

/* Initial Setup */

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --primary-color: #27404a;
  --secondary-color: #b56866;
  --text-color: #000000;
  --text-color-light: #f0e5df;
  --padding-inline-section: 20px;
  --hover-color: #102731;
}

h1{
  font-size: 2.986rem;
}

h2{
  font-size: 2.488rem;
}

h3{
  font-size: 2.074rem;
}

h4{
  font-size: 1.728rem;
}

h5{
  font-size: 1.44rem;
}

h6{
  font-size: 1.2rem;
}

a{
  text-decoration: none;
  display: inline-block;
}


ul{
  list-style: none;
}

p{
  font-family: 'Edensor', sans-serif;
  font-size: 1.25rem;
  color: var(--primary-color);
  line-height: 1.8rem;
}

/* utility classes */

.small-bold-text{
  font-size: 1rem;
  font-weight: 500;
}

.container{
  max-width: 1300px;
  margin-inline: auto;
  padding-inline: var(--padding-inline-section);
  overflow: hidden;
}

.split-container-child-1{
  width: 50%;
  padding-right: 50px;
}
.split-container-child-2{
  width: 50%;
  padding-left: 50px;
}

.flex{
  display: flex;
  align-items: center;
}

.hover-links{
  color: var(--primary-color);
  transition: 0.2s ease-out;
  font-weight: 500;
}

.hover-links:hover{
  color: var(--hover-color);
}

.primary-button{
  border: 2px solid var(--primary-color);
  padding: 6px 20px;
  border-radius: 6px;
  color: var(--text-color-light);
  background-color: var(--primary-color);
  transition: 0.5s ease;
}

.primary-button:hover{
  background-color: var(--hover-color);
  transform: translateY(-5px);
  transition: 0.5s ease;
}

.secondary-button{
  border: 2px solid var(--primary-color);
  padding: 6px 20px;
  border-radius: 6px;
  transition: 0.5s ease;
}

.secondary-button:hover{
  background-color: var(--hover-color);
  color: var(--text-color-light);
  transform: translateY(-5px);
  transition: 0.5s ease;
 
}

@media screen and (max-width: 1150px) { 
  .split-container-child-1{
    padding-right: 30px;
  }
  .split-container-child-2{
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .split-container-child-1{
    width: 100%;
  }
  .split-container-child-2{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: center;

  }
}


@media screen and (max-width: 425px) {
  .split-container-child-2{
    padding-left: 0px;
  }
}
