.newsletter-homepage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.newsletter-homepage-heading{
    font-family: 'Edensor', sans-serif;
    font-size: 60px;
    padding: 20px 0px;
}

.newsletter-homepage-description{
  text-align: center;
  width: 60%;
    font-family: 'Effra', sans-serif;
    font-size: 17px;
    font-weight: 300;
    margin-bottom: -5px;
}

.newsletter-form{
    margin-top: 20px;
}

.newsletter-form form {
    display:flex;
    flex-direction:row;
    color: #f5f0ed;
    border:1px solid var(--secondary-color);
    border-radius: 50px;
    padding:10px 30px;
  }
  
  .newsletter-form input {
    width: 500px;
    background-color: #f0e5df;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: var(--secondary-color);
    /* Tell the input to use all the available space */
    flex-grow:2;
    /* And hide the input's outline, so the form looks like the outline */
    border:none;
  }

  .newsletter-form input::placeholder{
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #d29b99;
  }

  .newsletter-form input::target-text{
    color: black;
  }
  
  /* remove the input focus blue box, it will be in the wrong place. */
  .newsletter-form input:focus {
    outline: none;
  }
  
  /* .newsletter-form form:focus-within { 
    outline: 1px solid blue 
  } */
  
  .newsletter-form button {
    /* Just a little styling to make it pretty */
    background:var(--secondary-color);
    padding: 5px 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    border-radius: 50px;
    color:white;
  }

  
  @media screen and (max-width: 768px) {
    .newsletter-homepage-heading{
      font-size: 50px;
  }
  .newsletter-form input {
    width: 250px;
  }
  }
  
  
  @media screen and (max-width: 425px) {
    .newsletter-homepage-heading{
      font-size: 35px;
  }
  .newsletter-homepage-description{
    width: 80%;
  }
    .newsletter-form input {
      width: 200px;
    }
  }