.why-kiah-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    margin-bottom: 80px;  
}

.why-kiah-sub-heading{
    font-family: 'Edensor' , sans-serif;
    font-size: 70px;
    color: white;
    padding-top: 30px;
    margin-bottom: 30px;
}

.why-kiah-sub-sub-heading{
    font-family: 'Effra' , sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    color: black;
}


/* Styling of one box */

.features-section{
    justify-content: space-between;
}

.single-feature{
    width: 28%;
    border-radius: 20px;
}

.feature-heading{
    margin-bottom: 10px;
}
.feature-heading img{
    width: 16%;
}

.feature-heading p{
    margin-left: 20px;
    font-family: 'Effra', sans-serif;
    font-size: 22px;
    color: white;
}

.feature-description p{
    font-family: 'Effra', sans-serif;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.3;
    color: black;
}


@media screen and (max-width: 1150px) { 

}

@media screen and (max-width: 768px) {
    .why-kiah-heading{
        margin-bottom: 50px;  
    }
    .why-kiah-sub-heading{
        font-size: 50px;
    }
    
    .why-kiah-sub-sub-heading{
        font-size: 15px;
    }

    .features-section{
        flex-wrap: wrap;
    }
    
    .single-feature{
        padding-top: 40px;
        width: 45%;
    }
}


@media screen and (max-width: 425px) {
    .why-kiah-heading{
        margin-bottom: 30px;  
    }
    .why-kiah-sub-heading{
        text-align: center;
        font-size: 40px;
    }
    
    .why-kiah-sub-sub-heading{
        text-align: center;
    }

    .single-feature{
        padding-top: 20px;
        width: 100%;
    }
}
