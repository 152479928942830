.products-heading{
    display: flex;
    justify-content: center;
    align-items: center;  
    margin-bottom: 80px;  
}

.our-products-heading{
    font-family: 'Edensor' , sans-serif;
    font-size: 80px;

}


/* Styling of one box */

.products-section{
    justify-content: space-between;
    padding-inline: 20px;
}


.single-product{
    width: 31%;
    background-color: white;
    border-radius: 20px;
box-shadow: 14.849px 14.849px 62px 0px rgba(12, 12, 59, 0.2);

      
}
.single-product img{
    width: 100%;
    border-radius: 20px 20px 0px 0px;
}

.single-product-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.single-product-name{
    color: #b85f5f;
    font-family: 'Georgia' , sans-serif;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 10px;
}

.single-product-description{
    font-family: 'Effra', sans-serif;
    font-size: 16px;
    text-align: center;
    font-weight: 300;
    line-height: 1.3;
    padding-bottom: 10px;
}

.single-product-reviews{
    padding: 10px 0px;
}

.single-product-reviews p{
    font-family: 'Edensor' , sans-serif;
    font-size: 18px;
    margin-left: 5px;
    font-weight: 600;
    color: #b85f5f;
}

.single-product-rating{
    border-right: 1px solid var(--primary-color);
    padding-right: 10px;
    margin-right: 5px;
}

.product-price{
    padding: 10px 0px;
}

.active-price{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 40px;
}

.inactive-price{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 20px;
    color: #768e98;
    text-decoration: line-through;
    margin-left: 10px;
}

.product-purchase-button{
    width: 100%;
    background-color: var(--primary-color);
    color: var(--text-color-light);
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 18px;
    padding: 8px 0px;
    border-radius: 50px;
    margin-top: 10px;
}

@media screen and (max-width: 1150px) { 
        
        .single-product-name{
            font-size: 24px;
            text-align: center;
        }
    }
    
    @media screen and (max-width: 768px) {
        .our-products-heading{
            font-size: 60px;
        }
        .products-section{
            flex-wrap: wrap;
          
        }
        .single-product{
            margin-top: 20px;
            width: 48%;
        }
    }
    
    
    @media screen and (max-width: 425px) {
        .products-heading{

            margin-bottom: 30px;  
        }
        
        .our-products-heading{
            font-size: 50px;
        }
        .products-section{
            flex-wrap: wrap;
          
        }
        .single-product{
            margin-top: 30px;
            width: 100%;
        }
    }