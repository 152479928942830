.about-section{
  margin-top: 80px;
  margin-bottom: 100px;
}

.about-newsletter{
    padding: 100px 0px;
    display: flex;
    align-items: center;
  }

.about-heading{
    font-family: 'Edensor', sans-serif;
    font-size: 50px;
    color: var(--secondary-color);
}

.about-divider{
    width: 45%;
    border: 1px solid #ccadab;
    margin-top: 30px;
    margin-bottom: 40px;
}

.about-subheading{
    font-family: 'Effra', sans-serif;
    font-size: 17px;
    font-weight: 300;
    color: var(--primary-color);
    line-height: 1.3;
}

.startup-india{
  background-color: #ccadab;
  padding-top: 100px;
  padding-bottom: 100px;
  border-top-right-radius: 400px;
}

.startup-heading{
  font-family: 'Edensor', sans-serif;
  font-size: 50px;
  color: white;
  margin-bottom: 40px;
}

.startup-subheading{
  font-family: 'Effra', sans-serif;
  font-size: 17px;
  font-weight: 200;
  color: var(--primary-color);
  line-height: 1.3;
}

.manufacturer-video{
  flex-direction: column;
  padding-top: 100px;
}

.manufacturer-video-description{
  font-family: 'Effra', sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: var(--secondary-color);
}

.manufacturer-video-heading{
  font-family: 'Edensor', sans-serif;
  margin-top: 20px;
  font-size: 60px;
  margin-bottom: 50px;
}

/* Mobile view */

@media screen and (max-width: 1150px) { 
        
}

@media screen and (max-width: 768px) {
    .about-section{
        display: flex;
        flex-direction: column;
    }

    .about-subheading{
        text-align: center;
    }    
    .about-heading{
        padding-top: 60px;
    }
}


@media screen and (max-width: 425px) {
    .about-heading{
        font-size: 35px;
    }
    .about-section{
      margin-top: 40px;
    }
    .startup-india-mobile{
      flex-direction: column;
    }
    .about-newsletter{
      padding: 30px 0px;
    }
    .manufacturer-video{
      padding: 1rem;
    }
    .manufacturer-video-description{
      text-align: center;
      font-size: 16px;
      margin-top: 25px;
    }

    .manufacturer-video-heading{
      text-align: center;
      font-size: 40px;
    }

    .startup-india img{
      margin-top: 40px;
    }
    .startup-heading{
      font-size: 40px;
    }
}