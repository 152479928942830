.homepage-about-heading{
    font-family: 'Edensor', sans-serif;
    font-size: 50px;
    color: var(--secondary-color);
}

.homepage-about-divider{
    width: 45%;
    border: 1px solid #ccadab;
    margin-top: 30px;
    margin-bottom: 40px;
}

.homepage-about-subheading{
    font-family: 'Effra', sans-serif;
    font-size: 17px;
    font-weight: 300;
    color: var(--primary-color);
    line-height: 1.3;
}


@media screen and (max-width: 1150px) { 
        
}

@media screen and (max-width: 768px) {
    .about-section-homepage{
        display: flex;
        flex-direction: column;
    }

    .homepage-about-subheading{
        text-align: center;
    }    
    .homepage-about-heading{
        padding-top: 60px;
    }
}


@media screen and (max-width: 425px) {
    .homepage-about-heading{
        font-size: 35px;
    }
}