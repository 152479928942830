
.home-slider{

    background-image: url('/public/images/landing-background.png');
    object-fit: contain;
    object-position: center;
}

.container-products{
    display: flex;
    align-items: center;
    max-width: 1150px;
    padding: 80px 0px;
  margin-inline: auto;
  padding-inline: 0;
  overflow: hidden;
}

.container-why-kiah{
  display: flex;
  align-items: center;
  background-color: #ccadab;
  padding: 100px 0px;
  border-radius: 20% 20% 0px 0px;
margin-inline: auto;
padding-inline: 0;
overflow: hidden;
}

.homepage-about{
  padding: 100px 0px;
  display: flex;
  align-items: center;
}

.homepage-quiz{
  padding: 100px 0px;
  display: flex;
  align-items: center;
  background-color: #f5f0ed;
}

.homepage-faq{
  padding: 100px 0px;
  display: flex;
  align-items: center;
  background-color: #ccadab;
  border-radius: 0px 0px 20% 20%;
}

.homepage-newsletter{
  padding: 100px 0px;
  display: flex;
  align-items: center;
}

.mobile-carousel{
  display: none;
}

@media screen and (max-width: 768px) {
  .container-why-kiah{
    padding: 75px 0px;
    border-radius: 10% 10% 0px 0px;
  }
}


@media screen and (max-width: 425px) {
  .container-why-kiah{
    padding: 50px 0px;
    border-radius: 10% 10% 0px 0px;
  }
  .desktop-carousel{
    display: none;
  }
  .mobile-carousel{
    display: block;
  }
}
